<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <div class="row">
              <h1 class="ml-3">Selamat Datang!</h1>
            </div>
            <!-- Card stats -->
        </base-header>


        <div class="container-fluid mt--7">
          <div class="row">
            <base-alert type="primary" dismissible>
              <span class="alert-inner--text"><strong>Perhatian!</strong> Apabila navigasi tidak muncul lengkap, silahkan logout dan login kembali, terima kasih</span>
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
            </base-alert>
          </div>
          <div class="row">
            <div class="col-xl-12 mb-5 mb-xl-0">
              <p>Promo Puri Bunda</p>
                <div class="row justify-content-md-center">
                  <div class="col-xl-4 mt-2 mb-3">
                    <img style="max-width:100%;max-height:100%;" src="@/assets/promo1.jpeg"/>  
                  </div>
                  <div class="col-xl-4 mt-2 mb-3">
                    <img style="max-width:100%;max-height:100%;" src="@/assets/promo2.jpeg"/>  
                  </div>
                  <div class="col-xl-4 mt-2 mb-3">
                    <img style="max-width:100%;max-height:100%;" src="@/assets/promo3.jpeg"/>  
                  </div>
                  <div class="col-xl-4 mt-2 mb-3">
                    <img style="max-width:100%;max-height:100%;" src="@/assets/promo4.jpeg"/>    
                  </div>
                  <div class="col-xl-4 mt-2 mb-3">
                    <img style="max-width:100%;max-height:100%;" src="@/assets/promo5.jpeg"/>  
                  </div>
                  <div class="col-xl-4 mt-2 mb-3">
                    <img style="max-width:100%;max-height:100%;" src="@/assets/promo6.jpeg"/>  
                  </div>
                </div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
  export default {
    components: {
      
    },
    data() {
      return {
        bigLineChart: {
          allData: [
            [0, 20, 10, 30, 15, 40, 20, 60, 60],
            [0, 20, 5, 25, 10, 30, 15, 40, 40]
          ],
          activeIndex: 0,
          chartData: {
            datasets: [],
            labels: [],
          },
        },
        redBarChart: {
          chartData: {
            labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
              label: 'Sales',
              data: [25, 20, 30, 22, 17, 29]
            }]
          }
        }
      };
    },
    methods: {
      initBigChart(index) {
        let chartData = {
          datasets: [
            {
              label: 'Performance',
              data: this.bigLineChart.allData[index]
            }
          ],
          labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        };
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      }
    },
    mounted() {
      if(localStorage.getItem("token") == null){
          this.$router.push({ name: 'login' })
      }
      this.initBigChart(0);
    }
  };
</script>
<style></style>
