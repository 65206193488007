<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <tabel-rujukan title="Tabel Pasien"></tabel-rujukan>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  import TabelRujukan from './Tables/TabelRujukan'
  export default {
    name: 'tables',
    components: {
      TabelRujukan
    },
    mounted(){
    if(localStorage.getItem("token") == null){
          this.$router.push({ name: 'login' })
      }
    }
  };
</script>
<style></style>
