<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col text-right">
          <base-button class="mb-0" v-if="role != 1" type="primary" @click="mitraAdd = true">Buat Data Mitra</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>Nama</th>
          <th>Username</th>
          <th>Klinik</th>
          <th>No Telp</th>
          <th>Alamat</th>
          <th>Sektor</th>
          <th>PIC</th>
          <th>Sales Call</th>
          <th>Point</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.user.name}}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{row.user.username}}
          </td>
          <td class="budget">
            {{row.klinik_name}}
          </td>
          <td class="budget">
            {{row.klinik_phone}}
          </td>
          <td class="budget">
            {{row.klinik_address}}
          </td>
          <td class="budget">
            {{ row.sector != null ? row.sector.sector_name : '-'}}
          </td>
          <td class="budget">
            {{row.pic.pic_name}}
          </td>
          <td class="budget">
            <base-button class="mb-0" type="primary" @click="editSalesCall(row)">Lihat</base-button>
          </td>
          <td class="budget">
            {{row.point}}
          </td>
          <td>
            <div class="media-body">
                <base-button v-if="role == '4'" @click="editMitra(row)" type="info">Edit</base-button>
              </div>
          </td>

        </template>

      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="meta.total"
                       :perPage="meta.per_page"
                       v-model="page"
      ></base-pagination>
    </div>
    <modal :show.sync="mitraAdd"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary" shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0">
            <template>
                <div class="text-muted mb-4">
                    <form role="form">
                      <base-input alternative
                                label="Nama Mitra"
                                class="mb-3"
                                placeholder="Nama"
                                v-model="model.user.name">
                      </base-input>
                      <base-input alternative
                                  label="Username"
                                  class="mb-3"
                                  placeholder="Username"
                                v-model="model.user.username">
                      </base-input>
                      <base-input alternative
                                  label="Nama Tempat Praktek"
                                  class="mb-3"
                                  placeholder="Nama Tempat Praktek"
                                v-model="model.klinik_name">
                      </base-input>
                      <base-input alternative
                                  label="Alamat Tempat Praktek"
                                  class="mb-3"
                                  placeholder="Alamat Tempat Praktek"
                                v-model="model.klinik_address">
                      </base-input>
                      <base-input alternative
                                  label="No Telp"
                                  class="mb-3"
                                  placeholder="No Telp"
                                v-model="model.klinik_phone">
                      </base-input>
                      <base-input alternative=""
                                  label="Sektor">
                          <v-select :options="option2" v-model="selected2"></v-select>
                      </base-input>
                      <base-input alternative=""
                                  v-if="role != '4'"
                                  label="PIC">
                        <v-select :options="option" v-model="selected"></v-select>
                      </base-input>
                    <div class="text-center">
                        <base-button type="primary" class="my-4" @click="saveData">Simpan</base-button>
                        <base-button type="danger" class="my-4" @click="function(){mitraAdd = false}">Batal</base-button>
                    </div>
                </form>
                </div>
                
            </template>
        </card>
    </modal>
    <modal :show.sync="mitraEdit"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary" shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0">
            <template>
                <div class="text-muted mb-4">
                    <form role="form">
                      <base-input alternative
                                label="Nama Mitra"
                                class="mb-3"
                                placeholder="Nama"
                                v-model="model.user.name">
                      </base-input>
                      <base-input alternative
                                  label="Username"
                                  class="mb-3"
                                  placeholder="Username"
                                v-model="model.user.username">
                      </base-input>
                      <base-input alternative
                                  label="Nama Tempat Praktek"
                                  class="mb-3"
                                  placeholder="Nama Tempat Praktek"
                                v-model="model.klinik_name">
                      </base-input>
                      <base-input alternative
                                  label="Alamat Tempat Praktek"
                                  class="mb-3"
                                  placeholder="Alamat Tempat Praktek"
                                v-model="model.klinik_address">
                      </base-input>
                      <base-input alternative
                                  label="No Telp"
                                  class="mb-3"
                                  placeholder="No Telp"
                                v-model="model.klinik_phone">
                      </base-input>
                      <base-input alternative=""
                                  label="Sektor">
                          <v-select :options="option2" v-model="selected2"></v-select>
                      </base-input>
                      <base-input alternative=""
                                  v-if="role != '4'"
                                  label="PIC">
                        <v-select :options="option" v-model="selected"></v-select>
                      </base-input>
                    <div class="text-center">
                        <base-button type="primary" class="my-4" @click="updateData">Simpan</base-button>
                        <base-button type="danger" class="my-4" @click="function(){mitraEdit = false}">Batal</base-button>
                    </div>
                </form>
                </div>
                
            </template>
        </card>
    </modal>

    <modal :show.sync="callEdit"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-md">
        <card type="secondary" shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-4 py-lg-4"
              class="border-0">
            <template>
                <div class="text-muted mb-4">
                  <h3 class="text-center">List Sales Call</h3>
                  <form role="form">
                    <v-calendar is-expanded color="green" :attributes="attributes" @dayclick="onDayClick" />
                    <div class="text-center">
                        <base-button type="primary" class="my-4" @click="updateDataSalesCall">Simpan</base-button>
                        <base-button type="danger" class="my-4" @click="function(){callEdit = false}">Batal</base-button>
                    </div>
                  </form>
                </div>
                
            </template>
        </card>
    </modal>

  </div>
</template>
<script>
  import moment from 'moment';
  export default {
    name: 'projects-table',
    props: {
      type: {
        type: String
      },
      title: String
    },
    data() {
      return {
        role: '',
        days: [],
        meta: {
          total : 0,
          per_page : 0,
        },
        page: 1,
        mitraAdd : false,
        mitraEdit : false,
        callEdit : false,
        option:[],
        option2:[],
        salesDate: '',
        tableData : [],
        model:{
          id : '',
          klinik_name:'',
          klinik_address:'',
          klinik_phone:'',
          klinik_sector:'',
          user: {
            name : '',
            username:'',
          }
        },
        selected: {
          id : '',
          label:''
        },
        selected2: {
          id : '',
          label : ''
        },
      }
    },
    mounted() {
      this.role = this.$CryptoJS.AES.decrypt(localStorage.getItem("role_crypt"),"SiRuLinOke").toString(this.$CryptoJS.enc.Utf8);
      this.$http
      .get(this.role != '4' ? process.env.VUE_APP_API_URL+"/api/kliniks" : process.env.VUE_APP_API_URL+"/api/kliniksPIC/"+this.$CryptoJS.AES.decrypt(localStorage.getItem("user_id_crypt"),"SiRuLinOke").toString(this.$CryptoJS.enc.Utf8))
      .then((response) => {
        this.tableData = response.data.data;
        this.meta = response.data.meta;
      });
      this.$http
      .get(process.env.VUE_APP_API_URL+"/api/pics")
      .then((response) => {
        response.data.data.forEach(el => {
          this.option.push({label: el.pic_name, id: el.id, user_id: el.user_id});
        });
        console.log(this.option);
      });
      this.$http
      .get(process.env.VUE_APP_API_URL+"/api/sectorsOption")
      .then((response) => {
        response.data.data.forEach(el => {
          this.option2.push({label: el.sector_name, id: el.id});
        });
        console.log(this.option2);
      });
    },
    filters: {
      moment: function (date) {
        return moment(date).format('LL');
      }
    },
    watch:{
      page(newVal){
        console.log(newVal)
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/kliniks?page="+newVal)
        .then((response) => {
          this.tableData = response.data.data;
          this.meta = response.data.meta;
        });
      },
      selected2(newVal){
        this.model.klinik_sector = newVal.id;
      }
    },
    computed: {
      dates() {
        console.log(this.days.map(day => day.date));
        return this.days.map(day => day.date);
      },
      attributes() {
        return this.dates.map(date => ({
          highlight: true,
          dates: date,
        }));
      },
    },
    methods:{
      onDayClick(day) {
        const idx = this.days.findIndex(d => d.id === day.id);
        if (idx >= 0) {
          this.days.splice(idx, 1);
        } else {
          this.days.push({
            id: day.id,
            date: day.date,
          });
        }
      },
      editMitra(row){
        this.model.id = row.id;
        this.model.user.name = row.user.name;
        this.model.user.username = row.user.username;
        this.model.klinik_name = row.klinik_name;
        this.model.klinik_address = row.klinik_address;
        this.model.klinik_phone = row.klinik_phone;
        this.selected.id = row.pic.id;
        this.selected.label = row.pic.pic_name;
        if(row.sector != null){
          this.selected2.id = row.sector.id;
          this.selected2.label = row.sector.sector_name;
        }
        this.mitraEdit = true;
      },
      editSalesCall(row){
        this.model.id = row.id;
        this.days = JSON.parse(row.sales_calls);
        this.callEdit = true;
      },
      saveData(){
        this.$http
        .post(process.env.VUE_APP_API_URL+"/api/kliniks", {
          'nama' : this.model.user.name,
          'username' : this.model.user.username,
          'klinik_name' : this.model.klinik_name,
          'klinik_address' : this.model.klinik_address,
          'klinik_phone' : this.model.klinik_phone,
          'klinik_sector' : this.model.klinik_sector,
          'pic_id' : this.role != "4" ? this.selected.id : this.option.find(x => String(x.user_id) == this.$CryptoJS.AES.decrypt(localStorage.getItem("user_id_crypt"),"SiRuLinOke").toString(this.$CryptoJS.enc.Utf8)).id,
        })
        .then((response) => {
          if(response.status == 201){
            this.$swal({
              title: "Berhasil",
              text: "Data Mitra ditambahkan",
              icon: "success"
            });
            this.model = 
            this.$http
            .get(this.role != '4' ? process.env.VUE_APP_API_URL+"/api/kliniks" : process.env.VUE_APP_API_URL+"/api/kliniksPIC/"+this.$CryptoJS.AES.decrypt(localStorage.getItem("user_id_crypt"),"SiRuLinOke").toString(this.$CryptoJS.enc.Utf8))
            .then((response) => {
              this.tableData = response.data.data;
              this.meta = response.data.meta;
            });
          } else {
            this.$swal({
              title: "Gagal",
              text: "Ada Kesalahan Pengiriman Data",
              icon: "error"
            })
          }
        });
      },
      updateData(){
        this.$http
        .put(process.env.VUE_APP_API_URL+"/api/kliniks/"+this.model.id, {
          'id' : this.model.id,
          'nama' : this.model.user.name,
          'username' : this.model.user.username,
          'klinik_name' : this.model.klinik_name,
          'klinik_address' : this.model.klinik_address,
          'klinik_phone' : this.model.klinik_phone,
          'klinik_sector' : this.model.klinik_sector,
          'pic_id' : this.role != "4" ? this.selected.id : this.option.find(x => String(x.user_id) == this.$CryptoJS.AES.decrypt(localStorage.getItem("user_id_crypt"),"SiRuLinOke").toString(this.$CryptoJS.enc.Utf8)).id,
        }, {
          "Access-Control-Allow-Origin": "*"
        })
        .then((response) => {
          if(response.status == 200){
            this.$swal({
              title: "Berhasil",
              text: "Data Mitra diedit",
              icon: "success"
            });
            this.mitraEdit = false;
            this.$http
            .get(this.role != '4' ? process.env.VUE_APP_API_URL+"/api/kliniks" : process.env.VUE_APP_API_URL+"/api/kliniksPIC/"+this.$CryptoJS.AES.decrypt(localStorage.getItem("user_id_crypt"),"SiRuLinOke").toString(this.$CryptoJS.enc.Utf8))
            .then((response) => {
              this.tableData = response.data.data;
              this.meta = response.data.meta;
            });
          } else {
            this.$swal({
              title: "Gagal",
              text: "Ada Kesalahan Pengiriman Data",
              icon: "error"
            })
          }
        });
      },
      updateDataSalesCall(){
        console.log(JSON.stringify(this.days))
        this.$http
        .put(process.env.VUE_APP_API_URL+"/api/klinkCall", {
          'id' : this.model.id,
          'date' : JSON.stringify(this.days),
        }, {
          "Access-Control-Allow-Origin": "*"
        })
        .then((response) => {
          if(response.status == 200){
            this.$swal({
              title: "Berhasil",
              text: "List Sales Call diedit",
              icon: "success"
            });
            this.callEdit = false;
            this.$http
            .get(this.role != '4' ? process.env.VUE_APP_API_URL+"/api/kliniks" : process.env.VUE_APP_API_URL+"/api/kliniksPIC/"+this.$CryptoJS.AES.decrypt(localStorage.getItem("user_id_crypt"),"SiRuLinOke").toString(this.$CryptoJS.enc.Utf8))
            .then((response) => {
              this.tableData = response.data.data;
              this.meta = response.data.meta;
            });
          } else {
            this.$swal({
              title: "Gagal",
              text: "Ada Kesalahan Pengiriman Data",
              icon: "error"
            })
          }
        });
      }
    }
  }
</script>
<style>
</style>
