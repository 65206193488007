<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Mask -->
            <span class="mask bg-gradient-success opacity-8"></span>
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">
                <div class="row">
                    <div class="col-lg-7 col-md-10">
                    </div>
                </div>
            </div>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Rujukan Baru</h3>
                                </div>
                            </div>
                        </div>
                        <template>
                            <form @submit.prevent>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <base-input alternative=""
                                                        label="Tanggal Rujukan"
                                                        placeholder="24-10-2020"
                                                        type="date"
                                                        required="true"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.date"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4" />
                                <h6 class="heading-small text-muted mb-4">Informasi Pasien</h6>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Nama"
                                                        placeholder="Nama"
                                                        required="true"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.name"
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Tanggal Lahir"
                                                        placeholder="24-10-2020"
                                                        type="date"
                                                        required="true"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.birthdate"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4" />
                                <!-- Address -->
                                <h6 class="heading-small text-muted mb-4">Informasi Kontak Pasien</h6>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <base-input alternative=""
                                                        label="No Telp"
                                                        placeholder="No Telp (angka 0-9)"
                                                        required="true"
                                                        @keypress="isNumber($event)"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.phone"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <base-input alternative=""
                                                        label="Alamat"
                                                        required="true"
                                                        placeholder="Alamat"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.address"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        required="true"
                                                        label="Provinsi">
                                                <v-select :options="option_prov" v-model="provinsi"></v-select>
                                            </base-input>
                                        </div>
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        required="true"
                                                        label="Kota/Kabupaten">
                                                <v-select :options="option_kab" v-model="kabupaten"></v-select>
                                            </base-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        required="true"
                                                        label="Kecamatan">
                                                <v-select :options="option_kec" v-model="kecamatan"></v-select>
                                            </base-input>
                                        </div>
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        required="true"
                                                        label="Desa/Kelurahan">
                                                <v-select :options="option_desa" v-model="kelurahan"></v-select>
                                            </base-input>
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4" />
                                <!-- Description -->
                                <h6 class="heading-small text-muted mb-4">Diagnosis & Tindakan</h6>
                                <div class="pl-lg-4">
                                    <div class="form-group">
                                        <base-input alternative=""
                                                    required="true"
                                                    label="Diagnosis">
                                            <textarea v-model="model.diagnosis" rows="4" class="form-control form-control-alternative" placeholder="Diagnosis"></textarea>
                                        </base-input>
                                    </div>
                                </div>
                                <div class="pl-lg-4">
                                    <div class="form-group">
                                        <base-input alternative=""
                                                    required="true"
                                                    label="Tindakan Awal">
                                            <textarea v-model="model.tindakan" rows="4" class="form-control form-control-alternative" placeholder="Tindakan yang sudah dilakukan"></textarea>
                                        </base-input>
                                    </div>
                                </div>
                                <div class="pl-lg-4">
                                    <div class="form-group">
                                        <base-button nativeType="submit" size="md" type="success" @click="saveData">Kirim</base-button>
                                    </div>
                                </div>
                            </form>
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseButton from '../components/BaseButton.vue';
import moment from 'moment';
  export default {
    name: 'user-profile',
    components: {
        BaseButton,
    },
    data() {
      return {
        model: {
          date: '',
          name: '',
          birthdate: '',
          phone: '',
          address: '',
          diagnosis: '',
          tindakan: '',
        },
        provinsi: '',
        kabupaten: '',
        kecamatan: '',
        kelurahan: '',
        option_prov: [],
        option_kab: [],
        option_kec: [],
        option_desa: [],
      }
    },
    mounted(){
        if(localStorage.getItem("token") == null){
          this.$router.push({ name: 'login' })
        }
        this.role = this.$CryptoJS.AES.decrypt(localStorage.getItem("role_crypt"),"SiRuLinOke").toString(this.$CryptoJS.enc.Utf8);
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/kode/provinsi")
        .then((response) => {
            response.data.data.forEach(el => {
                this.option_prov.push({label: el.provinsi, provinsi: el.provinsi});
            });
        });
    },
    methods: {
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        saveData(){
            var error = 0;
            var errorData = "";

            if(this.model.date == '')
            {
                if(error > 0){
                    errorData = errorData + ", "
                }
                errorData = errorData + "Tanggal Rujukan"
                error = error + 1
            }
            if(this.model.name == '')
            {
                if(error > 0){
                    errorData = errorData + ", "
                }
                errorData = errorData + "Nama Pasien"
                error = error + 1
            }
            if(this.model.birthdate == '')
            {
                if(error > 0){
                    errorData = errorData + ", "
                }
                errorData = errorData + "Tanggal Lahir"
                error = error + 1
            }
            if(this.model.phone == '')
            {
                if(error > 0){
                    errorData = errorData + ", "
                }
                errorData = errorData + "No Telp"
                error = error + 1
            }
            if(this.model.address == '')
            {
                if(error > 0){
                    errorData = errorData + ", "
                }
                errorData = errorData + "Alamat"
                error = error + 1
            }
            if(this.provinsi == '')
            {
                if(error > 0){
                    errorData = errorData + ", "
                }
                errorData = errorData + "Provinsi"
                error = error + 1
            }
            if(this.kabupaten == '')
            {
                if(error > 0){
                    errorData = errorData + ", "
                }
                errorData = errorData + "Kabupaten"
                error = error + 1
            }
            if(this.kecamatan == '')
            {
                if(error > 0){
                    errorData = errorData + ", "
                }
                errorData = errorData + "Kecamatan"
                error = error + 1
            }
            if(this.kelurahan == '')
            {
                if(error > 0){
                    errorData = errorData + ", "
                }
                errorData = errorData + "Kelurahan"
                error = error + 1
            }
            if(this.model.diagnosis == '')
            {
                if(error > 0){
                    errorData = errorData + ", "
                }
                errorData = errorData + "Diagnosis"
                error = error + 1
            }
            if(this.model.tindakan == '')
            {
                if(error > 0){
                    errorData = errorData + ", "
                }
                errorData = errorData + "Tindakan Akhir"
                error = error + 1
            }

            if(error == 0){
                this.$swal({
                title: 'Apakah Anda Yakin?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak'
                }).then((result) => {
                if (result.isConfirmed) {
                    this.$http
                        .post(process.env.VUE_APP_API_URL+"/api/rujukans", {
                        'rujukan_date' : moment(this.model.date).format('YYYY-MM-DD'),
                        'pasien_name' : this.model.name,
                        'pasien_dob' : moment(this.model.birthdate).format('YYYY-MM-DD'),
                        'phone' : this.model.phone,
                        'address' : this.model.address,
                        'province' : this.provinsi.provinsi,
                        'kabupaten' : this.kabupaten.kabupaten,
                        'kecamatan' : this.kecamatan.kecamatan,
                        'kelurahan' : this.kelurahan.kelurahan,
                        'diagnosis_first' : this.model.diagnosis,
                        'action_first' : this.model.tindakan,
                        })
                        .then((response) => {
                        if(response.status == 201){
                            this.$swal({
                            title: "Berhasil",
                            text: "Data Rujukan ditambahkan",
                            icon: "success"
                            });
                            this.model = {
                                date: '',
                                name: '',
                                birthdate: '',
                                phone: '',
                                address: '',
                                diagnosis: '',
                                tindakan: '',
                            };
                            this.provinsi = '';
                            this.kabupaten = '';
                            this.kecamatan = '';
                            this.kelurahan = '';
                            this.option_kab = [];
                            this.option_kec = [];
                            this.option_desa = [];
                        } else {
                            this.$swal({
                            title: "Gagal",
                            text: "Ada Kesalahan Pengiriman Data",
                            icon: "error"
                            })
                        }
                    });
                }
                });
            }
            else {
                this.$swal({
                title: "Gagal",
                text: "Harap isi " + errorData,
                icon: "error"
                })
            }
        }
    },
    watch: {
        provinsi(newVal){
            this.$http
            .get(process.env.VUE_APP_API_URL+"/api/kode/kabupaten/"+newVal.provinsi)
            .then((response) => {
                response.data.data.forEach(el => {
                    this.option_kab.push({label: el.kabupaten, kabupaten: el.kabupaten});
                });
            });
        },
        kabupaten(newVal){
            this.$http
            .get(process.env.VUE_APP_API_URL+"/api/kode/kecamatan/"+newVal.kabupaten)
            .then((response) => {
                response.data.data.forEach(el => {
                    this.option_kec.push({label: el.kecamatan, kecamatan: el.kecamatan});
                });
            });
        },
        kecamatan(newVal){
            this.$http
            .get(process.env.VUE_APP_API_URL+"/api/kode/kelurahan/"+newVal.kecamatan)
            .then((response) => {
                response.data.data.forEach(el => {
                    this.option_desa.push({label: el.kelurahan, kelurahan: el.kelurahan});
                });
            });
        }
    }
  };
</script>
<style></style>
