<template>
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary shadow border-0">
                    <div class="card-header bg-transparent">
                        <h1 class="text-center">Login</h1>
                    </div>
                    <div class="card-body px-lg-5 pt-lg-5">
                        <form role="form">
                            <base-input class="input-group-alternative mb-3"
                                        placeholder="Username"
                                        addon-left-icon="ni ni-circle-08"
                                        v-model="model.username">
                            </base-input>

                            <base-input class="input-group-alternative"
                                        placeholder="Password"
                                        type="password"
                                        addon-left-icon="ni ni-lock-circle-open"
                                        v-model="model.password">
                            </base-input>
                            <div class="text-center">
                                <base-button type="primary" class="my-4" @click="login">Sign in</base-button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-6">
                        
                    </div>
                    <div class="col-6 text-right">
                        --
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
  export default {
    name: 'login',
    data() {
      return {
        model: {
          username: '',
          password: ''
        }
      }
    },
    methods: {
        login() {
            this.$messaging.getToken({vapidKey: "BOqEhDqGY-Yfldg_BPeK33D3u2w7k4iOG0to3JvdGbOZ8aRb1Lw8zIWhha3wEIycFicxSUpW4RB81SsPxA85eCA"}).then((currentToken) => {
                if (currentToken) {
                    var bodyFormData = new FormData();
                    bodyFormData.set('username', this.model.username);
                    bodyFormData.set('password', this.model.password);
                    bodyFormData.set('token', currentToken);
                    this.$http({
                        method: 'post',
                        url: process.env.VUE_APP_API_URL+"/api/login",
                        data: bodyFormData,
                        headers: {'Content-Type': 'multipart/form-data' }
                    }).then(response => {
                        if(response.status == 200){
                            this.$http.defaults.headers.common['Authorization'] = "Bearer " + response.data.token.token
                            localStorage.setItem("token",response.data.token.token)
                            localStorage.setItem("role_crypt",this.$CryptoJS.AES.encrypt(response.data.user.role,"SiRuLinOke").toString())
                            var test = this.$CryptoJS.AES.encrypt(response.data.user.id.toString(),"SiRuLinOke").toString()
                            localStorage.setItem("user_id_crypt",test)
                            this.username = ''
                            this.password = ''
                            this.$router.push("/")
                        }
                        else{
                        this.$swal('Ups!', "Username / Password tidak sesuai!", 'error')
                        this.password = ''
                        } 
                    }).catch((err) => {
                        console.log(err)
                        this.$swal('Ups!', "Terjadi Kesalahan!", 'error')
                        this.password = ''
                    });
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                    this.$swal('Ups!', "Harap menyalakan fitur notifikasi!", 'error')
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                this.$swal('Ups!', "Terjadi kesalahan saat pengambilan token!", 'error')
            });
            // this.$messaging.getToken({vapidKey: "BOqEhDqGY-Yfldg_BPeK33D3u2w7k4iOG0to3JvdGbOZ8aRb1Lw8zIWhha3wEIycFicxSUpW4RB81SsPxA85eCA"}).then((currentToken) => {
            //     if (currentToken) {
            //         var bodyFormData = new FormData();
            //         bodyFormData.set('username', this.model.username);
            //         bodyFormData.set('password', this.model.password);
            //         bodyFormData.set('token', currentToken);
            //         this.$http({
            //             method: 'post',
            //             url: process.env.VUE_APP_API_URL+"/api/login",
            //             data: bodyFormData,
            //             headers: {'Content-Type': 'multipart/form-data' }
            //         }).then(response => {
            //             if(response.status == 200){
            //                 this.$http.defaults.headers.common['Authorization'] = "Bearer " + response.data.token.token
            //                 localStorage.setItem("token",response.data.token.token)
            //                 localStorage.setItem("role_crypt",this.$CryptoJS.AES.encrypt(response.data.user.role,"SiRuLinOke").toString())
            //                 var test = this.$CryptoJS.AES.encrypt(response.data.user.id.toString(),"SiRuLinOke").toString()
            //                 localStorage.setItem("user_id_crypt",test)
            //                 this.username = ''
            //                 this.password = ''
            //                 this.$router.push("/")
            //             }
            //             else{
            //             this.$swal('Ups!', "Username / Password tidak sesuai!", 'error')
            //             this.password = ''
            //             } 
            //         }).catch((err) => {
            //             console.log(err)
            //             this.$swal('Ups!', "Terjadi Kesalahan!", 'error')
            //             this.password = ''
            //         });
            //     } else {
            //         // Show permission request UI
            //         console.log('No registration token available. Request permission to generate one.');
            //     }
            // }).catch((err) => {
            //     console.log('An error occurred while retrieving token. ', err);
            // });
        }
    }
  }
</script>
<style>
</style>
