<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Mask -->
            <span class="mask bg-gradient-success opacity-8"></span>
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">
                <div class="row">
                    <div class="col-lg-7 col-md-10">
                    </div>
                </div>
            </div>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                        </div>
                        <template>
                            <form @submit.prevent>
                                <!-- Description -->
                                <h6 class="heading-small text-muted mb-4">Diagnosis & Tindakan</h6>
                                <div class="pl-lg-4">
                                    <div class="form-group">
                                        <base-input alternative=""
                                                    label="Diagnosis">
                                            <textarea v-model="model.diagnosis_first"
                                                        disabled rows="4" class="form-control form-control-alternative" placeholder="Diagnosis"></textarea>
                                        </base-input>
                                    </div>
                                </div>
                                <div class="pl-lg-4">
                                    <div class="form-group">
                                        <base-input alternative=""
                                                    label="Tindakan Awal">
                                            <textarea v-model="model.action_first"
                                                        disabled rows="4" class="form-control form-control-alternative" placeholder="Tindakan yang sudah dilakukan"></textarea>
                                        </base-input>
                                    </div>
                                    <div class="form-group">
                                        <base-input alternative=""
                                                    label="Jenis Tindakan">
                                            <v-select :options="options" v-model="model.action_last_type"></v-select>
                                        </base-input>
                                    </div>
                                    <div class="form-group">
                                        <base-input alternative=""
                                                    label="Tindakan Akhir">
                                            <textarea v-model="model.action_last"
                                                         rows="4" class="form-control form-control-alternative" placeholder="Tindakan Akhir"></textarea>
                                        </base-input>
                                    </div>
                                </div>
                                <div class="pl-lg-4">
                                    <div class="form-group">
                                        <base-button size="md" type="success" @click="saveData">Kirim</base-button>
                                    </div>
                                </div>
                            </form>
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseButton from '../components/BaseButton.vue';
  export default {
    name: 'user-profile',
    components: {
        BaseButton,
    },
    mounted() {
        if(localStorage.getItem("token") == null){
            this.$router.push({ name: 'login' })
        }
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/rujukans/"+this.$route.params.id)
        .then((response) => {
            this.model = response.data.data;
            this.selected = response.data.data.action_last_type;
        });
    },
    methods: {
        saveData(){
        this.$swal({
          title: 'Apakah Anda Yakin?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$http
                .put(process.env.VUE_APP_API_URL+"/api/rujukans/"+this.$route.params.id, this.model)
                .then((response) => {
                if(response.status == 200){
                    this.$swal({
                        title: "Berhasil",
                        text: "Data Rujukan ditambahkan",
                        icon: "success"
                    }).then((result) => {
                        if(result.isConfirmed){
                            this.$router.go(-1);
                        }
                    });
                } else {
                    this.$swal({
                    title: "Gagal",
                    text: "Ada Kesalahan Pengiriman Data",
                    icon: "error"
                    })
                }
            });
          }
        });
      }
    },
    data() {
      return {
          options: [
      'Persalinan',
      'Rawat Jalan',
      'Rawat Inap'
    ],
        model: {
            rujukan_date: '',
            pasien_name: '',
            pasien_dob: '',
            phone: '',
            address: '',
            diagnosis_first: '',
            action_first: '',
            action_last_type: '',
            action_last: '',
            provinsi: '',
            kabupaten: '',
            kecamatan: '',
            kelurahan: '',
        },
        selected: null,
      }
    },
    watch: {
        selected(newVal){
            this.model.action_last_type = newVal;
        }
    }
  };
</script>
