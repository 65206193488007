<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col text-right">
          <base-button type="info" @click="sectorAdd = true">Tambah Baru</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>Sektor</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <td scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.sector_name}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="media-body text-right">
                <base-button @click="editSector(row)" type="info">Edit</base-button>
                <base-button type="danger" @click="deleteData(row)">Delete</base-button>
              </div>
          </td>

        </template>

      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="meta.total"
      :perPage="meta.per_page"
                       v-model="page"
      ></base-pagination>
    </div>

    <modal :show.sync="sectorAdd"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary" shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0">
            <template>
                <div class="text-muted mb-4">
                    <form role="form">
                    <base-input alternative
                                label="Nama Sektor"
                                class="mb-3"
                                v-model="model.sector_name"
                                placeholder="Nama Sektor">
                    </base-input>
                    <div class="text-center">
                        <base-button type="primary" class="my-4" @click="saveData">Simpan</base-button>
                        <base-button type="danger" class="my-4" @click="sectorAdd = false">Batal</base-button>
                    </div>
                </form>
                </div>
            </template>
        </card>
    </modal>
    <modal :show.sync="sectorEdit"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary" shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0">
            <template>
                <div class="text-muted mb-4">
                    <form role="form">
                    <base-input alternative
                                label="Nama Sektor"
                                class="mb-3"
                                v-model="model.sector_name"
                                placeholder="Nama Sektor">
                    </base-input>
                    <div class="text-center">
                        <base-button type="primary" class="my-4" @click="updateData">Simpan</base-button>
                        <base-button type="danger" class="my-4" @click="sectorAdd = false">Batal</base-button>
                    </div>
                </form>
                </div>
            </template>
        </card>
    </modal>
  </div>
</template>
<script>
import BaseButton from '../../components/BaseButton.vue'
  export default {
  components: { BaseButton },
    name: 'projects-table',
    props: {
      type: {
        type: String
      },
      title: String
    },
    data() {
      return {
        tableData: [
        ],
        role: '',
        meta: {
          total : 0,
          per_page : 0,
        },
        page: 1,
        sectorAdd : false,
        sectorEdit : false,
        model:{
          id : '',
          sector_name : '',
        },
        selected: null,
      }
    },
    mounted() {
      this.role = this.$CryptoJS.AES.decrypt(localStorage.getItem("role_crypt"),"SiRuLinOke").toString(this.$CryptoJS.enc.Utf8);
      this.$http
      .get(process.env.VUE_APP_API_URL+"/api/sectors")
      .then((response) => {
        this.tableData = response.data.data;
        this.meta = response.data.meta;
      });
    },
    watch:{
      page(newVal){
        console.log(newVal)
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/sectors?page="+newVal)
        .then((response) => {
          this.tableData = response.data.data;
          this.meta = response.data.meta;
        });
      }
    },
    methods:{
      saveData(){
        this.$http
        .post(process.env.VUE_APP_API_URL+"/api/sectors", {
          'sector_name' : this.model.sector_name,
        })
        .then((response) => {
          if(response.status == 201){
            this.$swal({
              title: "Berhasil",
              text: "Data sector ditambahkan",
              icon: "success"
            })
            this.sectorAdd = false;
            this.model.id = '';
            this.model.sector_name = '';
            this.$http
            .get(process.env.VUE_APP_API_URL+"/api/sectors")
            .then((response) => {
              this.tableData = response.data.data;
              this.meta = response.data.meta;
            });
          } else {
            this.$swal({
              title: "Gagal",
              text: "Ada Kesalahan Pengiriman Data",
              icon: "error"
            })
          }
        });
      },
      editSector(row){
        this.model.id = row.id;
        this.model.sector_name = row.sector_name;
        this.sectorEdit = true;
      },
      updateData(){
        this.$http
        .post(process.env.VUE_APP_API_URL+"/api/sectors", {
          'id' : this.model.id,
          'sector_name' : this.model.sector_name,
        })
        .then((response) => {
          if(response.status == 200){
            this.$swal({
              title: "Berhasil",
              text: "Data sector diedit",
              icon: "success"
            })
            this.sectorEdit = false;
            this.model.id = '';
            this.model.sector_name = '';
            this.$http
            .get(process.env.VUE_APP_API_URL+"/api/sectors")
            .then((response) => {
              this.tableData = response.data.data;
              this.meta = response.data.meta;
            });
          } else {
            this.$swal({
              title: "Gagal",
              text: "Ada Kesalahan Pengiriman Data",
              icon: "error"
            })
          }
        });
      },
      deleteData(row){
        this.$http
        .delete(process.env.VUE_APP_API_URL+"/api/sectors/"+row.id)
        .then((response) => {
          if(response.status == 200){
            this.$swal({
              title: "Berhasil",
              text: "Data sector ditambahkan",
              icon: "success"
            })
            this.userAdd = false;
            this.$http
            .get(process.env.VUE_APP_API_URL+"/api/sectors")
            .then((response) => {
              this.tableData = response.data.data;
              this.meta = response.data.meta;
            });
          } else {
            this.$swal({
              title: "Gagal",
              text: "Ada Kesalahan Pengiriman Data",
              icon: "error"
            })
          }
        });
      }
    }
  }
</script>
<style>
</style>
