<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow"
                        :class="type === 'dark' ? 'bg-default': ''">
                      <div class="card-header border-0"
                          :class="type === 'dark' ? 'bg-transparent': ''">
                        <div class="row align-items-center">
                          <div class="col">
                            <h2 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                              Report
                            </h2>
                          </div>
                          <div class="col text-right">
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-6">
                            <base-input alternative=""
                                        label="Bulan">
                              <v-select :clearable="false" :options="months" v-model="monthSelect"></v-select>
                            </base-input>
                          </div>
                          <div class="col-md-6">
                            <base-input alternative=""
                                        label="Tahun">
                              <v-select :clearable="false" :options="years" v-model="yearSelect"></v-select>
                            </base-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <h2 class="text-center">Grafik Trend Registrasi Rujukan Per Tahun</h2>
                            <bar-chart :chart-data="datacollection7" :options="defaultOptions3()"></bar-chart>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <h2 class="text-center">Grafik Jumlah Rujukan Per Sektor</h2>
                            <doughnut-chart :chart-data="datacollection" :options="defaultOptions2()"></doughnut-chart>
                          </div>
                          <div class="col-md-6">
                            <h2 class="text-center">Grafik Jumlah Rujukan Per Mitra</h2>
                            <doughnut-chart :chart-data="datacollection2" :options="defaultOptions2()"></doughnut-chart>
                          </div>
                        </div>
                        <div class="row mt-5">
                          <div class="col-md-4">
                            <h2 class="text-center">Grafik Jumlah Rujukan Per Kecamatan Pasien</h2>
                            <doughnut-chart :chart-data="datacollection3" :options="defaultOptions2()"></doughnut-chart>
                          </div>
                          <div class="col-md-4">
                            <h2 class="text-center">Grafik Jumlah Rujukan Per Tipe Tindakan</h2>
                            <doughnut-chart :chart-data="datacollection4" :options="defaultOptions2()"></doughnut-chart>
                          </div>
                          <div class="col-md-4">
                            <h2 class="text-center">Grafik Persentase Registrasi Pasien</h2>
                            <doughnut-chart :chart-data="datacollection6" :options="defaultOptions2()"></doughnut-chart>
                          </div>
                        </div>
                        <div class="row mt-5">
                          <div class="col-md-12">
                            <h2 class="text-center">Grafik Input Rujukan Per Tanggal</h2>
                            <line-chart :chart-data="datacollection5" :options="defaultOptions()"></line-chart>
                          </div>
                        </div>
                        <div class="col-md-12 mt-4">
                          <h2 class="text-center">Tabel 10 Besar</h2>
                          <div class="table-responsive">
                            <base-table class="table align-items-center table-flush"
                                        :class="type === 'dark' ? 'table-dark': ''"
                                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                                        tbody-classes="list"
                                        :data="top10Mitra">
                              <template slot="columns">
                                <th>Nama</th>
                                <th>Klinik</th>
                                <th>Point</th>
                              </template>

                              <template slot-scope="{row}">
                                <th scope="row">
                                  <div class="media align-items-center">
                                    <div class="media-body">
                                      <span class="name mb-0 text-sm">{{row.user.name}}</span>
                                    </div>
                                  </div>
                                </th>
                                <td class="budget">
                                  {{row.klinik_name}}
                                </td>
                                <td class="budget">
                                  {{row.point}}
                                </td>
                              </template>
                            </base-table>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import DoughnutChart from './Charts/DoughnutChart'
  import LineChart from './Charts/LineChart'
  import BarChart from './Charts/BarChart'
  export default {
    name: 'tables',
    components: {
      DoughnutChart,
      LineChart,
      BarChart
    },
    data () {
      return {
        datacollection: null,
        datacollection2: null,
        datacollection3: null,
        datacollection4: null,
        datacollection5: null,
        datacollection6: null,
        datacollection7: null,
        dataMonthly: [],
        dataMonthlyReg: [],
        datayearlyRI: [],
        datayearlyRJ: [],
        datayearlyPS: [],
        top10Mitra: [],
        months: [
          1,2,3,4,5,6,7,8,9,10,11,12
        ],
        yearSelect: new Date().getFullYear(),
        monthSelect: new Date().getMonth()+1,
      }
    },
    mounted(){
      this.fillData(this.yearSelect,this.monthSelect)
      if(localStorage.getItem("token") == null){
          this.$router.push({ name: 'login' })
      }
    },
    methods: {
      fillData(year,month) {
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/report/"+year+"/"+month)
        .then((response) => {
          this.top10Mitra = response.data.data.top10Mitra
          var perSektor = response.data.data.dataPerSektor
          var labelSektor = []
          var dataSektor = []
          var bgColor = [
            '#00876c',
            '#3c986d',
            '#61a96e',
            '#85b96f',
            '#aac872',
            '#d0d678',
            '#f7e382',
            '#f7c96c',
            '#f5af5c',
            '#f19452',
            '#ea784d',
            '#e15b4e',
            '#d43d51']
            var bgColor2 = [
            '#00876c',
            '#d43d51']

          var yearlyRI = response.data.data.dataTrendRegistrasiTahunanRI
          var labelyearlyRI = []
          this.datayearlyRI = []

          var yearlyRJ = response.data.data.dataTrendRegistrasiTahunanRJ
          var labelyearlyRJ = []
          this.datayearlyRJ = []

          var yearlyPS = response.data.data.dataTrendRegistrasiTahunanPS
          var labelyearlyPS = []
          this.datayearlyPS = []

          var perMitra = response.data.data.dataPerMitra
          var labelMitra = []
          var dataMitra = []

          var perKecamatan = response.data.data.dataPerKecamatan
          var labelKecamatan = []
          var dataKecamatan = []

          var perTindakan = response.data.data.dataPerTindakan
          var labelTindakan = []
          var dataTindakan = []

          var labelRegis = []
          var dataRegis = []

          var perMonthly = response.data.data.dataMonthly
          var labelMonthly = []

          this.dataMonthlyReg = []
          this.dataMonthly = []
          var perMonthlyReg = response.data.data.dataMonthlyReg
          var labelMonthlyReg = []

          Object.keys(yearlyRI).forEach(key => {
            labelyearlyRI.push(parseInt(key) + 1)
            this.datayearlyRI.push(yearlyRI[key])
          });

          Object.keys(yearlyRJ).forEach(key => {
            labelyearlyRJ.push(parseInt(key) + 1)
            this.datayearlyRJ.push(yearlyRJ[key])
          });

          Object.keys(yearlyPS).forEach(key => {
            labelyearlyPS.push(parseInt(key) + 1)
            this.datayearlyPS.push(yearlyPS[key])
          });

          this.datacollection7 = {
            labels: labelyearlyRI,
            datasets: [
              {
                label: "Data Registrasi Rawat Inap",
                backgroundColor: '#00876c',
                data: this.datayearlyRI
              },
              {
                label: "Data Registrasi Rawat Jalan",
                backgroundColor: '#d43d51',
                data: this.datayearlyRJ
              },
              {
                label: "Data Registrasi Persalinan",
                backgroundColor: '#5662F6',
                data: this.datayearlyPS
              }
            ]
          }

          perSektor.forEach(key => {
            labelSektor.push(key.sector_name)
            dataSektor.push(key.jumlah)
          })
          this.datacollection = {
            labels: labelSektor,
            datasets: [
              {
                backgroundColor:bgColor,
                data: dataSektor
              }
            ]
          }

          perMitra.forEach(key => {
            labelMitra.push(key.name)
            dataMitra.push(key.jumlah)
          })
          this.datacollection2 = {
            labels: labelMitra,
            datasets: [
              {
                backgroundColor:bgColor,
                data: dataMitra
              }
            ]
          }

          perKecamatan.forEach(key => {
            labelKecamatan.push(key.kecamatan)
            dataKecamatan.push(key.jumlah)
          })
          this.datacollection3 = {
            labels: labelKecamatan,
            datasets: [
              {
                backgroundColor:bgColor,
                data: dataKecamatan
              }
            ]
          }

          perTindakan.forEach(key => {
            labelTindakan.push(key.tindakan)
            dataTindakan.push(key.jumlah)
          })
          this.datacollection4 = {
            labels: labelTindakan,
            datasets: [
              {
                backgroundColor:bgColor,
                data: dataTindakan
              }
            ]
          }

          labelRegis.push("Sudah Registrasi")
          dataRegis.push(response.data.data.dataSudahRegistrasiMonthly[0].jumlah)
          labelRegis.push("Belum Registrasi")
          dataRegis.push(response.data.data.dataBelumRegistrasiMonthly[0].jumlah)

          this.datacollection6 = {
            labels: labelRegis,
            datasets: [
              {
                backgroundColor:bgColor2,
                data: dataRegis
              }
            ]
          }
          console.log(this.datacollection3)
          console.log(this.datacollection6)

          Object.keys(perMonthlyReg).forEach(key => {
            labelMonthlyReg.push(parseInt(key) + 1)
            this.dataMonthlyReg.push(perMonthlyReg[key])
          });
          
          Object.keys(perMonthly).forEach(key => {
            labelMonthly.push(parseInt(key) + 1)
            this.dataMonthly.push(perMonthly[key])
          });
          this.datacollection5 = {
            labels: labelMonthly,
            datasets: [
              {
                label: "Data Input Rujukan",
                backgroundColor: '#00876c',
                data: this.dataMonthly
              },
              {
                label: "Data Registrasi Rujukan",
                backgroundColor: '#d43d51',
                data: this.dataMonthlyReg
              }
            ]
          }
        });
      },
      defaultOptions () {
        return {
          maintainAspectRatio: false,
          legend: {
            display: true
          },
          plugins: {
            labels: {
              render: 'value',
              fontColor: ['white'],
              fontSize: ({ chart }) => {
                const { bottom, left, right, top } = chart.chartArea;
                const size = Math.round(Math.min(bottom - top, right - left) / 16);
                return size;
              },
              precision: 2,
            },
          },
          scales: {
            xAxes: [{
              gridLines: {
                drawOnChartArea: false
              }
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 10,
                stepSize: (Math.max.apply(Math, this.dataMonthly) > 100 ? 50 : 10),
                max: Math.max.apply(Math, this.dataMonthly) + 10
              },
              gridLines: {
                display: true
              }
            }]
          },
          elements: {
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4,
              hoverBorderWidth: 3
            }
          }
        }
      },
      defaultOptions2 () {
        return {
          plugins: {
            labels: {
              render: 'percentage',
              fontColor: "#000000",
              fontSize: 15,
              precision: 2,
            },
          },
        }
      },
      defaultOptions3 () {
        var arr = [this.datayearlyRI, this.datayearlyRJ, this.datayearlyPS]
        return {
          maintainAspectRatio: false,
          legend: {
            display: true
          },
          plugins: {
            labels: {
              render: 'value',
              fontColor: ['white'],
              fontSize: ({ chart }) => {
                const { bottom, left, right, top } = chart.chartArea;
                const size = Math.round(Math.min(bottom - top, right - left) / 16);
                return size;
              },
              precision: 2,
            },
          },
          scales: {
            xAxes: [{
              gridLines: {
                drawOnChartArea: false
              }
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 10,
                stepSize: (Math.max.apply(null, arr.map(function(row){ return Math.max.apply(Math, row); })) > 100 ? 50 : 10),
                max: Math.max.apply(null, arr.map(function(row){ return Math.max.apply(Math, row); })) + 5
              },
              gridLines: {
                display: true
              }
            }]
          },
          elements: {
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4,
              hoverBorderWidth: 3
            }
          }
        }
      },
      getRandomInt () {
        return Math.floor(Math.random() * (50 - 5 + 1)) + 5
      },
      getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }
    },
    watch:{
      yearSelect(newVal){
        this.fillData(newVal,this.monthSelect)
      },
      monthSelect(newVal){
        this.fillData(this.yearSelect,newVal)
      }
    },
    computed:{
      years(){
        var min = 2020
        var max = new Date().getFullYear()
        var yearlsit = []

        for (var i = max; i >= min; i--) {
          yearlsit.push(i)
        }
        return yearlsit
      }
    }
  };
</script>
<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>
