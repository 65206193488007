<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col text-right">
          <base-button class="mb-0" type="primary" @click="userAdd = true">Buat Data User</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>Nama</th>
          <th>Username</th>
          <th>Role</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.name}}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{row.username}}
          </td>
          <td class="budget">
            {{row.role == 1 ? "Admin" : row.role == 2 ? "Operator" : row.role == 4 ? "PIC" : "Keuangan"}}
          </td>
          <td>
            <div class="media-body budget">
                <!-- <base-button v-if="row.role == 4" type="primary">List Mitra</base-button> -->
                <!-- <base-button @click="resetPass" type="info">Reset Password</base-button> -->
              </div>
          </td>

        </template>

      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="meta.total"
                       :perPage="meta.per_page"
                       v-model="page"
      ></base-pagination>
    </div>
    <modal :show.sync="userAdd"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary" shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0">
            <template>
                <div class="text-muted mb-4">
                    <form role="form">
                      <base-input alternative=""
                                  label="Role">
                        <v-select :options="option" v-model="selected"></v-select>
                      </base-input>
                      <base-input alternative
                                  label="Nama"
                                  v-if="selected != null"
                                  class="mb-3"
                                  placeholder="Nama"
                                  v-model="model.name">
                      </base-input>
                      <base-input alternative
                                  label="Username"
                                  v-if="selected != null"
                                  class="mb-3"
                                  placeholder="Username"
                                  v-model="model.username">
                      </base-input>
                      <base-input alternative
                                  label="Password"
                                  v-if="selected != null && selected.role != '4'"
                                  class="mb-3"
                                  type="password"
                                  placeholder="Password"
                                  v-model="model.password">
                      </base-input>
                      <base-input alternative
                                  label="No Telp"
                                  v-if="selected != null && selected.role == '4'"
                                  class="mb-3"
                                  placeholder="No Telp"
                                v-model="model.pic_phone">
                      </base-input>
                    <div class="text-center">
                        <base-button type="primary" class="my-4" @click="saveData">Simpan</base-button>
                        <base-button type="danger" class="my-4" @click="function(){userAdd = false; selected = null}">Batal</base-button>
                    </div>
                </form>
                </div>
                
            </template>
        </card>
    </modal>

  </div>
</template>
<script>
  export default {
    name: 'projects-table',
    props: {
      type: {
        type: String
      },
      title: String
    },
    data() {
      return {
        tableData: [
        ],
        role: '',
        meta: {
          total : 0,
          per_page : 0,
        },
        page: 1,
        userAdd : false,
        option:[
          { label : "Admin", role : "1" },
          { label : "Operator", role : "2" },
          { label : "PIC", role : "4" },
          { label : "Keuangan", role : "5" }
        ],
        model:{
          name : '',
          role: '',
          username:'',
          pic_phone:'',
          password: '',
        },
        selected: null,
      }
    },
    
    mounted() {
      this.role = this.$CryptoJS.AES.decrypt(localStorage.getItem("role_crypt"),"SiRuLinOke").toString(this.$CryptoJS.enc.Utf8);
      this.$http
      .get(process.env.VUE_APP_API_URL+"/api/users")
      .then((response) => {
        this.tableData = response.data.data;
        this.meta = response.data.meta;
      });
    },
    watch:{
      page(newVal){
        console.log(newVal)
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/users?page="+newVal)
        .then((response) => {
          this.tableData = response.data.data;
          this.meta = response.data.meta;
        });
      }
    },
    methods:{
      resetPass(){
        this.$swal({
          title: 'Apakah Anda Yakin?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal(
              'Berhasil!',
              'Password di-reset.',
              'success'
            )
          }
        });
      },
      saveData(){
        if(this.selected.role == 1 || this.selected.role == 2 || this.selected.role == 5){
        this.$http
        .post(process.env.VUE_APP_API_URL+"/api/users", {
          'name' : this.model.name,
          'username' : this.model.username,
          'password' : this.model.password,
          'role' : this.selected.role,
        })
        .then((response) => {
          if(response.status == 201){
            this.$swal({
              title: "Berhasil",
              text: "Data User ditambahkan",
              icon: "success"
            })
            this.userAdd = false;
            this.$http
            .get(process.env.VUE_APP_API_URL+"/api/users")
            .then((response) => {
              this.tableData = response.data.data;
              this.meta = response.data.meta;
            });
          } else {
            this.$swal({
              title: "Gagal",
              text: "Ada Kesalahan Pengiriman Data",
              icon: "error"
            })
          }
        });
        } else {
        this.$http
        .post(process.env.VUE_APP_API_URL+"/api/pics", {
          'name' : this.model.name,
          'username' : this.model.username,
          'pic_name' : this.model.name,
          'pic_phone' : this.model.pic_phone,
        })
        .then((response) => {
          if(response.status == 201){
            this.$swal({
              title: "Berhasil",
              text: "Data PIC ditambahkan",
              icon: "success"
            })
            this.userAdd = false;
            this.$http
            .get(process.env.VUE_APP_API_URL+"/api/users")
            .then((response) => {
              this.tableData = response.data.data;
              this.meta = response.data.meta;
            });
          } else {
            this.$swal({
              title: "Gagal",
              text: "Ada Kesalahan Pengiriman Data",
              icon: "error"
            })
          }
        });
        }
      }
    }
  }
</script>
<style>
</style>
