<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow"
                        :class="type === 'dark' ? 'bg-default': ''">
                      <div class="card-header border-0"
                          :class="type === 'dark' ? 'bg-transparent': ''">
                        <div class="row align-items-center">
                          <div class="col">
                            <h2 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                              Report
                            </h2>
                          </div>
                          <div class="col text-right">
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-6">
                            <VueDatePicker v-model="date1"
                                           :max-date="date2"
                                           placeholder="Pilih Tanggal" />
                          </div>
                          <div class="col-md-6">
                            <VueDatePicker v-model="date2"
                                           :min-date="date1"
                                           placeholder="Pilih Tanggal" />
                          </div>
                        </div>
                        <div class="col-md-12 mt-4">
                          <h2 class="text-center">Laporan Rujukan</h2>
                          <div class="table-responsive">
                            <template>
                              <v-data-table
                                :headers="headers"
                                :items="data"
                                :items-per-page="30"
                                :hide-default-footer="true"
                                class="elevation-1"
                              >
                              </v-data-table>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import moment from 'moment';
  export default {
    name: 'tables',
    components: {
    },
    data () {
      return {
         headers: [
          { text: 'Klinik', value: 'klinik_name' },
          { text: 'Rawat Jalan Closed', align: 'center', value: 'RJclosed' },
          { text: 'Rawat Jalan', align: 'center', value: 'RJ' },
          { text: 'Rawat Inap Closed', align: 'center', value: 'RIclosed' },
          { text: 'Rawat Inap', align: 'center', value: 'RI' },
          { text: 'Persalinan Closed', align: 'center', value: 'Persalinanclosed' },
          { text: 'Persalinan', align: 'center', value: 'Persalinan' },
          { text: 'Total Closed', align: 'center', value: 'rujukanclosed' },
          { text: 'Total Rujukan', align: 'center', value: 'totalrujukan' },
        ],
        data:[],
        date1: moment().subtract(1, "days").format('MM/DD/YYYY'),
        date2: moment().format('MM/DD/YYYY'),
      }
    },
    mounted(){
      this.fillData(this.date1,this.date2)
      if(localStorage.getItem("token") == null){
          this.$router.push({ name: 'login' })
      }
    },
    methods: {
      fillData(date1,date2) {
        var fdate1 = moment(date1).format("YYYY-MM-DD") 
        var fdate2 = moment(date2).format("YYYY-MM-DD") 
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/reportPIC/"+fdate1+"/"+fdate2)
        .then((response) => {
          this.data = response.data.data
          var totaled = this.data.reduce((acc, d) => {
            acc.RJclosed += d.RJclosed
            acc.RJ += d.RJ
            acc.RIclosed += d.RIclosed
            acc.RI += d.RI
            acc.Persalinanclosed += d.Persalinanclosed
            acc.Persalinan += d.Persalinan
            acc.rujukanclosed += d.rujukanclosed
            acc.totalrujukan += d.totalrujukan
            return acc
          }, {
              klinik_name: "Total",
              RJclosed: 0,
              RJ: 0,
              RIclosed: 0,
              RI: 0,
              Persalinanclosed: 0,
              Persalinan: 0,
              rujukanclosed: 0,
              totalrujukan: 0,
            })
          this.data = this.data.concat(totaled)
        });
      },
    },
    watch:{
      date1(newVal){
        this.fillData(newVal,this.date2)
      },
      date2(newVal){
        this.fillData(this.date1,newVal)
      }
    },
    computed:{
      years(){
        var min = 2020
        var max = new Date().getFullYear()
        var yearlsit = []

        for (var i = max; i >= min; i--) {
          yearlsit.push(i)
        }
        return yearlsit
      },
      totals() {
        const totals = this.data.reduce((acc, d) => {
          acc.RJclosed += d.RJclosed
          acc.RJ += d.RJ
          acc.RIclosed += d.RIclosed
          acc.RI += d.RI
          acc.Persalinanclosed = d.Persalinanclosed
          acc.Persalinan = d.Persalinan
          acc.rujukanclosed = d.rujukanclosed
          acc.totalrujukan = d.totalrujukan
          return acc
        }, {
            RJclosed: 0,
            RJ: 0,
            RIclosed: 0,
            RI: 0,
            Persalinanclosed: 0,
            Persalinan: 0,
            rujukanclosed: 0,
            totalrujukan: 0,
          })
        return totals
      }
    }
  };
</script>
<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>
