<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow"
                        :class="type === 'dark' ? 'bg-default': ''">
                      <div class="card-header border-0"
                          :class="type === 'dark' ? 'bg-transparent': ''">
                        <div class="row align-items-center">
                          <div class="col">
                            <h2 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                              Report
                            </h2>
                          </div>
                          <div class="col text-right">
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-6">
                            <VueDatePicker v-model="date1"
                                           :max-date="date2"
                                           placeholder="Pilih Tanggal" />
                          </div>
                          <div class="col-md-6">
                            <VueDatePicker v-model="date2"
                                           :min-date="date1"
                                           placeholder="Pilih Tanggal" />
                          </div>
                        </div>
                        <div class="col-md-12 mt-4">
                          <h2 class="text-center">Tabel Registrasi Rujukan</h2>
                          <div class="table-responsive">
                            <template>
                              <v-data-table
                                :headers="headers"
                                :items="desserts"
                                :single-expand="singleExpand"
                                :expanded.sync="expanded"
                                :items-per-page="10"
                                class="elevation-1"
                              >
                              <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                  More info about {{ item.tanggal }}
                                </td>
                              </template>
                              </v-data-table>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import moment from 'moment';
  export default {
    name: 'tables',
    components: {
    },
    data () {
      return {
         headers: [
          {
            text: 'Tanggal',
            value: 'tanggal',
          },
          { text: 'Rawat Jalan', value: 'calories' },
          { text: 'Rawat Inap', value: 'fat' },
          { text: 'Persalinan', value: 'carbs' },
          { text: 'Protein (g)', value: 'protein' },
          { text: 'Iron (%)', value: 'iron' },
        ],
        data:[],
        date1: moment().subtract(1, "days").format('MM/DD/YYYY hh:mm'),
        date2: moment().format('MM/DD/YYYY hh:mm'),
      }
    },
    mounted(){
      this.fillData(this.date1,this.date2)
      if(localStorage.getItem("token") == null){
          this.$router.push({ name: 'login' })
      }
    },
    methods: {
      fillData(date1,date2) {
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/reportKeuangan/"+date1+"/"+date2)
        .then((response) => {
          this.top10Mitra = response.data.data.top10Mitra
        });
      },
    },
    watch:{
      date1(newVal){
        this.fillData(newVal,this.date2)
      },
      date2(newVal){
        this.fillData(this.date1,newVal)
      }
    },
    computed:{
      years(){
        var min = 2020
        var max = new Date().getFullYear()
        var yearlsit = []

        for (var i = max; i >= min; i--) {
          yearlsit.push(i)
        }
        return yearlsit
      }
    }
  };
</script>
<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>
