<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col text-right">
          <base-button type="info" @click="benefitAdd = true">Tambah Baru</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>Item</th>
          <th>Performance</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.item_name}}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{row.point}}
          </td>
          <td>
            <div class="media-body">
                <base-button type="danger" @click="deleteData(row)">Delete</base-button>
              </div>
          </td>

        </template>

      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="meta.total"
      :perPage="meta.per_page"
                       v-model="page"
      ></base-pagination>
    </div>

    <modal :show.sync="benefitAdd"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary" shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0">
            <template>
                <div class="text-muted mb-4">
                    <form role="form">
                    <base-input alternative
                                label="Item"
                                class="mb-3"
                                v-model="model.item_name"
                                placeholder="Item">
                    </base-input>
                    <base-input alternative
                    type="number"
                                label="Performance"
                                min="0"
                                v-model="model.point"
                                placeholder="Performance">
                    </base-input>
                    <div class="text-center">
                        <base-button type="primary" class="my-4" @click="saveData">Simpan</base-button>
                        <base-button type="danger" class="my-4" @click="benefitAdd = false">Batal</base-button>
                    </div>
                </form>
                </div>
                
            </template>
        </card>
    </modal>
  </div>
</template>
<script>
import BaseButton from '../../components/BaseButton.vue'
  export default {
  components: { BaseButton },
    name: 'projects-table',
    props: {
      type: {
        type: String
      },
      title: String
    },
    data() {
      return {
        tableData: [
        ],
        role: '',
        meta: {
          total : 0,
          per_page : 0,
        },
        page: 1,
        benefitAdd : false,
        model:{
          item_name : '',
          point: '',
        },
        selected: null,
      }
    },
    
    mounted() {
      this.role = this.$CryptoJS.AES.decrypt(localStorage.getItem("role_crypt"),"SiRuLinOke").toString(this.$CryptoJS.enc.Utf8);
      this.$http
      .get(process.env.VUE_APP_API_URL+"/api/benefits")
      .then((response) => {
        this.tableData = response.data.data;
        this.meta = response.data.meta;
      });
    },
    watch:{
      page(newVal){
        console.log(newVal)
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/benefits?page="+newVal)
        .then((response) => {
          this.tableData = response.data.data;
          this.meta = response.data.meta;
        });
      }
    },
    methods:{
      resetPass(){
        this.$swal({
          title: 'Apakah Anda Yakin?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal(
              'Berhasil!',
              'Password di-reset.',
              'success'
            )
          }
        });
      },
      saveData(){
        this.$http
        .post(process.env.VUE_APP_API_URL+"/api/benefits", {
          'item_name' : this.model.item_name,
          'point' : this.model.point,
        })
        .then((response) => {
          if(response.status == 201){
            this.$swal({
              title: "Berhasil",
              text: "Data Benefit ditambahkan",
              icon: "success"
            })
            this.benefitAdd = false;
            this.model.point = '';
            this.model.item_name = '';
            this.$http
            .get(process.env.VUE_APP_API_URL+"/api/benefits")
            .then((response) => {
              this.tableData = response.data.data;
              this.meta = response.data.meta;
            });
          } else {
            this.$swal({
              title: "Gagal",
              text: "Ada Kesalahan Pengiriman Data",
              icon: "error"
            })
          }
        });
      },
      deleteData(row){
        this.$http
        .delete(process.env.VUE_APP_API_URL+"/api/benefits/"+row.id)
        .then((response) => {
          if(response.status == 200){
            this.$swal({
              title: "Berhasil",
              text: "Data Benefit ditambahkan",
              icon: "success"
            })
            this.userAdd = false;
            this.$http
            .get(process.env.VUE_APP_API_URL+"/api/benefits")
            .then((response) => {
              this.tableData = response.data.data;
              this.meta = response.data.meta;
            });
          } else {
            this.$swal({
              title: "Gagal",
              text: "Ada Kesalahan Pengiriman Data",
              icon: "error"
            })
          }
        });
      }
    }
  }
</script>
<style>
</style>
