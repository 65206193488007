<template>
  <div>
    <div class="card shadow"
        :class="type === 'dark' ? 'bg-default': ''">
      <div class="card-header border-0"
          :class="type === 'dark' ? 'bg-transparent': ''">
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
              {{title}}
            </h3>
          </div>
          <div class="col text-right">            
            <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
              <i>*) Halaman akan refresh otomatis setiap 1 menit</i>
            </p>
          </div>
        </div>
      </div>

      <tabs fill class="m-2 flex-column flex-md-row">
          <card shadow>
              <tab-pane>
                  <span slot="title">
                      Belum Registrasi
                  </span>
                  <div class="row">
                    <div class="col-md-12">
                        <base-input placeholder="Pencarian...." v-model="search1"></base-input>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <base-table class="table align-items-center table-flush"
                                :class="type === 'dark' ? 'table-dark': ''"
                                :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                                tbody-classes="list"
                                :data="tableData1">
                      <template slot="columns">
                        <th>Kode Rujukan</th>
                        <th>Nama</th>
                        <th>No Telp</th>
                        <th>Status</th>
                        <th>Tanggal Rujukan</th>
                        <th>Tindakan Akhir</th>
                        <th>Update Terakhir</th>
                        <th></th>
                      </template>

                      <template slot-scope="{row}">
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.kode_rujukan}}</span>
                            </div>
                        </td>
                        <th scope="row">
                          <div class="media align-items-center">
                            <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.pasien_name}}</span>
                            </div>
                          </div>
                        </th>
                        <td class="budget">
                          {{row.phone}}
                        </td>
                        <td>
                          <badge class="badge-dot mr-4" :type="row.statusType">
                            <i :class="`bg-${row.statusType}`"></i>
                            <span class="status">{{statusList[row.status]}}</span>
                          </badge>
                        </td>
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.rujukan_date}}</span>
                            </div>
                        </td>
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.action_last_type}}</span>
                            </div>
                        </td>
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.updated_at | moment}}</span>
                            </div>
                        </td>
                        <td>
                          <div class="media-body">
                              <base-button @click="showDet(row)" type="info">Detail</base-button>
                              <base-button tag="a" :href="'#/edit-rujukan/' + row.id" type="primary" v-if='role == "2" && row.statusType == "success" && row.statusType != "default" '>Edit</base-button>
                              <base-button tag="a" :href="'#/edit-rujukan-pic/' + row.id" type="primary" v-if='(role == "4") && row.statusType == "danger" && row.statusType != "default" '>Edit</base-button>
                              <base-button @click="setReg(row)" v-if='role == "2" && row.statusType != "default"' :type="row.statusType == 'success' ? 'danger' : 'success'">{{ row.statusType == "success" ? 'Set Belum Registrasi' : 'Set Registrasi'}}</base-button>
                              <base-button @click="setClose(row)" type="default" v-if='role == "2" && row.statusType == "success" && row.statusType != "default" && row.action_last_type != "-" '>Close</base-button>
                            </div>
                        </td>
                      </template>

                    </base-table>
                    <div class="card-footer d-flex justify-content-end"
                        :class="type === 'dark' ? 'bg-transparent': ''">
                      <base-pagination :total="meta1.total"
                                      :perPage="meta1.per_page"
                                      v-model="page1"
                      ></base-pagination>
                    </div>
                  </div>
              </tab-pane>

              <tab-pane title="Profile">
                  <span slot="title">
                      Registrasi
                  </span>
                  <div class="row">
                    <div class="col-md-12">
                        <base-input placeholder="Pencarian...." v-model="search2"></base-input>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <base-table class="table align-items-center table-flush"
                                :class="type === 'dark' ? 'table-dark': ''"
                                :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                                tbody-classes="list"
                                :data="tableData2">
                      <template slot="columns">
                        <th>Kode Rujukan</th>
                        <th>Nama</th>
                        <th>No Telp</th>
                        <th>Status</th>
                        <th>Tanggal Rujukan</th>
                        <th>Tindakan Akhir</th>
                        <th>Update Terakhir</th>
                        <th></th>
                      </template>

                      <template slot-scope="{row}">
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.kode_rujukan}}</span>
                            </div>
                        </td>
                        <th scope="row">
                          <div class="media align-items-center">
                            <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.pasien_name}}</span>
                            </div>
                          </div>
                        </th>
                        <td class="budget">
                          {{row.phone}}
                        </td>
                        <td>
                          <badge class="badge-dot mr-4" :type="row.statusType">
                            <i :class="`bg-${row.statusType}`"></i>
                            <span class="status">{{statusList[row.status]}}</span>
                          </badge>
                        </td>
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.rujukan_date}}</span>
                            </div>
                        </td>
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.action_last_type}}</span>
                            </div>
                        </td>
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.updated_at | moment}}</span>
                            </div>
                        </td>
                        <td>
                          <div class="media-body">
                              <base-button @click="showDet(row)" type="info">Detail</base-button>
                              <base-button tag="a" :href="'#/edit-rujukan/' + row.id" type="primary" v-if='role == "2" && row.statusType == "success" && row.statusType != "default" '>Edit</base-button>
                              <base-button tag="a" :href="'#/edit-rujukan-pic/' + row.id" type="primary" v-if='(role == "4") && row.statusType == "danger" && row.statusType != "default" '>Edit</base-button>
                              <base-button @click="setReg(row)" v-if='role == "2" && row.statusType != "default"' :type="row.statusType == 'success' ? 'danger' : 'success'">{{ row.statusType == "success" ? 'Set Belum Registrasi' : 'Set Registrasi'}}</base-button>
                              <base-button @click="setClose(row)" type="default" v-if='role == "2" && row.statusType == "success" && row.statusType != "default" && row.action_last_type != "-" '>Close</base-button>
                            </div>
                        </td>
                      </template>

                    </base-table>
                    <div class="card-footer d-flex justify-content-end"
                        :class="type === 'dark' ? 'bg-transparent': ''">
                      <base-pagination :total="meta2.total"
                                      :perPage="meta2.per_page"
                                      v-model="page2"
                      ></base-pagination>
                    </div>
                  </div>
              </tab-pane>

              <tab-pane>
                  <span slot="title">
                      Closed
                    </span>
                  <div class="row">
                    <div class="col-md-12">
                        <base-input placeholder="Pencarian...." v-model="search3"></base-input>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <base-table class="table align-items-center table-flush"
                                :class="type === 'dark' ? 'table-dark': ''"
                                :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                                tbody-classes="list"
                                :data="tableData3">
                      <template slot="columns">
                        <th>Kode Rujukan</th>
                        <th>Nama</th>
                        <th>No Telp</th>
                        <th>Status</th>
                        <th>Tanggal Rujukan</th>
                        <th>Tindakan Akhir</th>
                        <th>Update Terakhir</th>
                        <th></th>
                      </template>

                      <template slot-scope="{row}">
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.kode_rujukan}}</span>
                            </div>
                        </td>
                        <th scope="row">
                          <div class="media align-items-center">
                            <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.pasien_name}}</span>
                            </div>
                          </div>
                        </th>
                        <td class="budget">
                          {{row.phone}}
                        </td>
                        <td>
                          <badge class="badge-dot mr-4" :type="row.statusType">
                            <i :class="`bg-${row.statusType}`"></i>
                            <span class="status">{{statusList[row.status]}}</span>
                          </badge>
                        </td>
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.rujukan_date}}</span>
                            </div>
                        </td>
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.action_last_type}}</span>
                            </div>
                        </td>
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.updated_at | moment}}</span>
                            </div>
                        </td>
                        <td>
                          <div class="media-body">
                              <base-button @click="showDet(row)" type="info">Detail</base-button>
                              <base-button tag="a" :href="'#/edit-rujukan/' + row.id" type="primary" v-if='role == "2" && row.statusType == "success" && row.statusType != "default" '>Edit</base-button>
                              <base-button tag="a" :href="'#/edit-rujukan-pic/' + row.id" type="primary" v-if='(role == "4") && row.statusType == "danger" && row.statusType != "default" '>Edit</base-button>
                              <base-button @click="setReg(row)" v-if='role == "2" && row.statusType != "default"' :type="row.statusType == 'success' ? 'danger' : 'success'">{{ row.statusType == "success" ? 'Set Belum Registrasi' : 'Set Registrasi'}}</base-button>
                              <base-button @click="setClose(row)" type="default" v-if='role == "2" && row.statusType == "success" && row.statusType != "default" && row.action_last_type != "-" '>Close</base-button>
                            </div>
                        </td>
                      </template>

                    </base-table>
                    <div class="card-footer d-flex justify-content-end"
                        :class="type === 'dark' ? 'bg-transparent': ''">
                      <base-pagination :total="meta3.total"
                                      :perPage="meta3.per_page"
                                      v-model="page3"
                      ></base-pagination>
                    </div>
                  </div>
              </tab-pane>

              <tab-pane>
                  <span slot="title">
                      Expired
                    </span>
                  <div class="row">
                    <div class="col-md-12">
                        <base-input placeholder="Pencarian...." v-model="search4"></base-input>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <base-table class="table align-items-center table-flush"
                                :class="type === 'dark' ? 'table-dark': ''"
                                :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                                tbody-classes="list"
                                :data="tableData4">
                      <template slot="columns">
                        <th>Kode Rujukan</th>
                        <th>Nama</th>
                        <th>No Telp</th>
                        <th>Status</th>
                        <th>Tanggal Rujukan</th>
                        <th>Tindakan Akhir</th>
                        <th>Update Terakhir</th>
                        <th></th>
                      </template>

                      <template slot-scope="{row}">
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.kode_rujukan}}</span>
                            </div>
                        </td>
                        <th scope="row">
                          <div class="media align-items-center">
                            <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.pasien_name}}</span>
                            </div>
                          </div>
                        </th>
                        <td class="budget">
                          {{row.phone}}
                        </td>
                        <td>
                          <badge class="badge-dot mr-4" :type="row.statusType">
                            <i :class="`bg-${row.statusType}`"></i>
                            <span class="status">Expired</span>
                          </badge>
                        </td>
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.rujukan_date}}</span>
                            </div>
                        </td>
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.action_last_type}}</span>
                            </div>
                        </td>
                        <td>
                          <div class="media-body">
                              <span class="name mb-0 text-sm">{{row.updated_at | moment}}</span>
                            </div>
                        </td>
                        <td>
                          <div class="media-body">
                              <base-button @click="showDet(row)" type="info">Detail</base-button>
                              <base-button tag="a" :href="'#/edit-rujukan/' + row.id" type="primary" v-if='role == "2" && row.statusType == "success" && row.statusType != "default" '>Edit</base-button>
                              <base-button tag="a" :href="'#/edit-rujukan-pic/' + row.id" type="primary" v-if='(role == "4") && row.statusType == "danger" && row.statusType != "default" '>Edit</base-button>
                              <base-button @click="setReg(row)" v-if='role == "2" && row.statusType != "default"' :type="row.statusType == 'success' ? 'danger' : 'success'">{{ row.statusType == "success" ? 'Set Belum Registrasi' : 'Set Registrasi'}}</base-button>
                              <base-button @click="setClose(row)" type="default" v-if='role == "2" && row.statusType == "success" && row.statusType != "default" && row.action_last_type != "-" '>Close</base-button>
                            </div>
                        </td>
                      </template>

                    </base-table>
                    <div class="card-footer d-flex justify-content-end"
                        :class="type === 'dark' ? 'bg-transparent': ''">
                      <base-pagination :total="meta4.total"
                                      :perPage="meta4.per_page"
                                      v-model="page4"
                      ></base-pagination>
                    </div>
                  </div>
              </tab-pane>
          </card>
      </tabs>

      <modal :show.sync="detailMode"
              body-classes="p-0"
              modal-classes="modal-dialog-centered modal-sm">
          <card type="secondary" shadow
                header-classes="bg-white pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0">
              <template>
                  <div class="mb-4">
                      <p>Nama: <br> {{selected.pasien_name}}</p>
                      <p>Alamat: <br> {{selected.address + ', ' + selected.kelurahan + ', ' + selected.kecamatan + ', ' + selected.kabupaten + ', ' + selected.province}}</p>
                      <p>Tanggal Lahir: <br> {{selected.pasien_dob}}</p>
                      <p>Bidan Perujuk: <br> {{selected.mitra.user.name}}</p>
                      <p>PIC: <br> {{selected.mitra.pic.pic_name}}</p>
                      <p>Telp Bidan Perujuk: <br> {{selected.mitra.klinik_phone}}</p>
                      <p>Diagnosa: <br> {{selected.diagnosis_first}}</p>
                      <p>Tindakan: <br> {{selected.action_first}}</p>
                  <base-button type="danger" class="my-4" @click="detailMode = false">Tutup</base-button>
                  </div>
                  
              </template>
          </card>
      </modal>

    </div>
  </div>
</template>
<script>
import moment from 'moment'
  export default {
    name: 'projects-table',
    props: {
      type: {
        type: String
      },
      title: String
    },
    data() {
      return {
        role: '',
        meta1: {
          total : 0,
          per_page : 0,
        },
        meta2: {
          total : 0,
          per_page : 0,
        },
        meta3: {
          total : 0,
          per_page : 0,
        },
        meta4: {
          total : 0,
          per_page : 0,
        },
        page1: 1,
        page2: 1,
        page3: 1,
        page4: 1,
        search1: '',
        search2: '',
        search3: '',
        search4: '',
        statusList: {
          0 : "Belum Registrasi",
          1 : "Registrasi",
          2 : "Closed",
        },
        tableData1 : [],
        tableData2 : [],
        tableData3 : [],
        tableData4 : [],
        detailMode : false,
        selected: {
          pasien_name: '',
          address: '',
          kelurahan: '',
          kecamatan: '',
          kabupaten: '',
          province: '',
          pasien_dob: '',
          diagnosis_first: '',
          action_first: '',
          mitra:{
            klinik_phone: '',
            pic: {
              pic_name: '',
            },
            user:{
              name: '',
            }
          }
        }
      }
    },
    filters: {
      moment: function (date) {
        return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      }
    },
    mounted() {
      this.role = this.$CryptoJS.AES.decrypt(localStorage.getItem("role_crypt"),"SiRuLinOke").toString(this.$CryptoJS.enc.Utf8);
      this.fetchData();
      this.timer = setInterval(this.fetchData, 60000);
    },
    watch:{
      page1(newVal){
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/rujukansBR?page="+newVal)
        .then((response) => {
          this.tableData1 = response.data.data;
          this.tableData1.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
          this.meta1 = response.data.meta;
        });
      },
      search1(newVal){
        this.page1 = 1
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/rujukansBR?page=1&search="+newVal)
        .then((response) => {
          this.tableData1 = response.data.data;
          this.tableData1.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
          this.meta1 = response.data.meta;
        });
      },
      page2(newVal){
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/rujukansR?page="+newVal)
        .then((response) => {
          this.tableData2 = response.data.data;
          this.tableData2.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
          this.meta2 = response.data.meta;
        });
      },
      search2(newVal){
        this.page2 = 1
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/rujukansR?page=1&search="+newVal)
        .then((response) => {
          this.tableData2 = response.data.data;
          this.tableData2.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
          this.meta2 = response.data.meta;
        });
      },
      page3(newVal){
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/rujukansC?page="+newVal)
        .then((response) => {
          this.tableData3 = response.data.data;
          this.tableData3.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
          this.meta3 = response.data.meta;
        });
      },
      search3(newVal){
        this.page3 = 1
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/rujukansC?page=1&search="+newVal)
        .then((response) => {
          this.tableData3 = response.data.data;
          this.tableData3.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
          this.meta3 = response.data.meta;
        });
      },
      page4(newVal){
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/rujukansE?page="+newVal)
        .then((response) => {
          this.tableData4 = response.data.data;
          this.tableData4.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
          this.meta4 = response.data.meta;
        });
      },
      search4(newVal){
        this.page4 = 1
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/rujukansE?page=1&search="+newVal)
        .then((response) => {
          this.tableData4 = response.data.data;
          this.tableData4.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
          this.meta4 = response.data.meta;
        });
      },
    },
    methods:{
      fetchData(){
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/rujukansBR")
        .then((response) => {
          this.tableData1 = response.data.data;
          this.tableData1.forEach(element => {
            if(element.status == '0'){
              element.statusType = 'danger';
            } else if(element.status == '1'){
              element.statusType = 'success';
            } else {
              element.statusType = 'default';
            }
          });
          this.meta1 = response.data.meta;
        });
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/rujukansR")
        .then((response) => {
          this.tableData2 = response.data.data;
          this.tableData2.forEach(element => {
            if(element.status == '0'){
              element.statusType = 'danger';
            } else if(element.status == '1'){
              element.statusType = 'success';
            } else {
              element.statusType = 'default';
            }
          });
          this.meta2 = response.data.meta;
        });
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/rujukansC")
        .then((response) => {
          this.tableData3 = response.data.data;
          this.tableData3.forEach(element => {
            if(element.status == '0'){
              element.statusType = 'danger';
            } else if(element.status == '1'){
              element.statusType = 'success';
            } else {
              element.statusType = 'default';
            }
          });
          this.meta3 = response.data.meta;
        });
        this.$http
        .get(process.env.VUE_APP_API_URL+"/api/rujukansE")
        .then((response) => {
          this.tableData4 = response.data.data;
          this.tableData4.forEach(element => {
            if(element.status == '0'){
              element.statusType = 'danger';
            } else if(element.status == '1'){
              element.statusType = 'success';
            } else {
              element.statusType = 'default';
            }
          });
          this.meta4 = response.data.meta;
        });
      },
      setReg(row){
        this.$swal({
          title: 'Yakin?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'green',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$http
            .put(process.env.VUE_APP_API_URL+"/api/rujukans/"+row.id, {
              'id' : row.id,
              'rujukan_date' : moment(row.rujukan_date).format('YYYY-MM-DD'),
              'pasien_name' : row.pasien_name,
              'pasien_dob' : moment(row.pasien_dob).format('YYYY-MM-DD'),
              'phone' : row.phone,
              'address' : row.address,
              'province' : row.province,
              'kabupaten' : row.kabupaten,
              'kecamatan' : row.kecamatan,
              'kelurahan' : row.kelurahan,
              'diagnosis_first' : row.diagnosis_first,
              'action_first' : row.action_first,
              'action_last_type' : "-",
              'action_last' : "-",
              'status' : row.status == '0' ? '1' : '0',
            })
            .then((response) =>{
              if(response.status == 200){
                this.$swal(
                  'Berhasil!',
                  'data tersimpan.',
                  'success'
                );
                this.role = this.$CryptoJS.AES.decrypt(localStorage.getItem("role_crypt"),"SiRuLinOke").toString(this.$CryptoJS.enc.Utf8);
                this.$http
                .get(process.env.VUE_APP_API_URL+"/api/rujukansBR")
                .then((response) => {
                  this.tableData1 = response.data.data;
                  this.tableData1.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
                  this.meta1 = response.data.meta;
                });
                this.$http
                .get(process.env.VUE_APP_API_URL+"/api/rujukansR")
                .then((response) => {
                  this.tableData2 = response.data.data;
                  this.tableData2.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
                  this.meta2 = response.data.meta;
                });
                this.$http
                .get(process.env.VUE_APP_API_URL+"/api/rujukansC")
                .then((response) => {
                  this.tableData3 = response.data.data;
                  this.tableData3.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
                  this.meta3 = response.data.meta;
                });
                this.$http
                .get(process.env.VUE_APP_API_URL+"/api/rujukansE")
                .then((response) => {
                  this.tableData4 = response.data.data;
                  this.tableData4.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
                  this.meta4 = response.data.meta;
                });
              }
            })
          }
        });
      },
      setClose(row){
        this.$swal({
          title: 'Yakin?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'green',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$http
            .put(process.env.VUE_APP_API_URL+"/api/rujukans/"+row.id, {
              'id' : row.id,
              'rujukan_date' : moment(row.rujukan_date).format('YYYY-MM-DD'),
              'pasien_name' : row.pasien_name,
              'pasien_dob' : moment(row.pasien_dob).format('YYYY-MM-DD'),
              'phone' : row.phone,
              'address' : row.address,
              'province' : row.province,
              'kabupaten' : row.kabupaten,
              'kecamatan' : row.kecamatan,
              'kelurahan' : row.kelurahan,
              'diagnosis_first' : row.diagnosis_first,
              'action_first' : row.action_first,
              'action_last' : row.action_last,
              'diagnosis_last' : row.diagnosis_last,
              'status' : '2',
            })
            .then((response) =>{
              if(response.status == 200){
                this.$swal(
                  'Berhasil!',
                  'data tersimpan.',
                  'success'
                );
                
                this.get(process.env.VUE_APP_API_URL+"/api/rujukansBR")
                .then((response) => {
                  this.tableData1 = response.data.data;
                  this.tableData1.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
                  this.meta1 = response.data.meta;
                });
                this.$http
                .get(process.env.VUE_APP_API_URL+"/api/rujukansR")
                .then((response) => {
                  this.tableData2 = response.data.data;
                  this.tableData2.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
                  this.meta2 = response.data.meta;
                });
                this.$http
                .get(process.env.VUE_APP_API_URL+"/api/rujukansC")
                .then((response) => {
                  this.tableData3 = response.data.data;
                  this.tableData3.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
                  this.meta3 = response.data.meta;
                });
                this.$http
                .get(process.env.VUE_APP_API_URL+"/api/rujukansE")
                .then((response) => {
                  this.tableData4 = response.data.data;
                  this.tableData4.forEach(element => {
                    if(element.status == '0'){
                      element.statusType = 'danger';
                    } else if(element.status == '1'){
                      element.statusType = 'success';
                    } else {
                      element.statusType = 'default';
                    }
                  });
                  this.meta4 = response.data.meta;
                });
              }
            })
          }
        });
      },
      showDet(row){
        this.selected = row;
        this.detailMode = true;
      }
    }
  }
</script>
<style>
</style>
